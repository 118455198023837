<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
            <h4 class="mb-sm-0">Role</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'role' }">Daftar Role</router-link>
                </li>
                <li class="breadcrumb-item active">Form Role</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">{{ title }}</h4>
              <div class="flex-shrink-0">
                <router-link :to="{ name: 'role' }" type="button" class="btn btn-warning waves-effect waves-light"><i
                    class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                  Kembali
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <form class="" @submit.prevent="submit">
                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Nama</label>
                  </div>
                  <div class="col-md-5">
                    <input v-model="role.name" type="text" class="form-control" required />
                  </div>
                </div>
                
                <div class="mb-3"></div>
                <div class="mb-4">
                  <label class="form-label mt-2">Permission</label>
                  <div class="table-responsive">
                    <table class="table caption-top ttable-bordered table-hover">
                      <thead class="table-light">
                        <tr>
                          <th scope="col">Module</th>
                          <th scope="col">View</th>
                          <th scope="col">Create</th>
                          <th scope="col">Edit</th>
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody v-if="permissions.length <= 0">
                        <table-shimmer :column="5" :row="10"></table-shimmer>
                      </tbody>
                      <tbody v-else>
                        <tr v-for="(item, index) in permissions" :key="item">
                          <th scope="row">{{ item["module"] }}</th>
                          <td>
                            <input v-if="permissions[index]['view']" class="form-check-input" type="checkbox"
                              v-model="permissions[index]['view']['value']" />
                          </td>
                          <td>
                            <input v-if="permissions[index]['create']" class="form-check-input" type="checkbox"
                              v-model="permissions[index]['create']['value']"
                              :checked="permissions[index]['create']['id']" />
                          </td>
                          <td>
                            <input v-if="permissions[index]['edit']" class="form-check-input" type="checkbox"
                              v-model="permissions[index]['edit']['value']"
                              :checked="permissions[index]['edit']['id']" />
                          </td>
                          <td>
                            <input v-if="permissions[index]['delete']" class="form-check-input" type="checkbox"
                              v-model="permissions[index]['delete']['value']"
                              :checked="permissions[index]['delete']['id']" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="float-end">
                  <router-link :to="{ name: 'role' }" type="button"
                    class="btn btn-warning waves-effect waves-light me-2"><i
                      class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                    Kembali
                  </router-link>
                  <button :disabled="load" type="submit" class="btn btn-primary waves-effect waves-light">
                    <i class="bx font-size-16 align-middle me-2"
                      :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"></i>
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpService from "../../services/http-service";
import { endpoint } from "../../host";
import Swal from "sweetalert2";
import errorService from "../../services/error-service";
import TableShimmer from "../../components/shimmer/TableShimmer.vue";
import permissonService from '../../services/permisson-service';
export default {
  components: {
    TableShimmer,
  },
  async created() {
    let params = this.$route.params;
    if (params.id) {
      this.role.id = params.id;
      await this.showRole();
      this.title = "Edit Role";
    }
    this.getPermissions();
  },
  data() {
    return {
      title: "Tambah Role",
      load: false,
      role: {
        id: null,
        name: null,
        _method: null,
        permission_id: [],
      },
      permissions: [],
      access_levels: [],
    };
  },
  methods: {

    async submit() {
      this.role.permission_id = this.getCheckedPermission();
      try {
        this.load = true;
        let res = await httpService.post(
          endpoint["role"] + (this.role.id ? `/${this.role.id}` : ""),
          this.role
        );
        this.load = false;
        if (res.status == 200) {
          Swal.fire({
            title: "Berhasil",
            text: "data berhasil disimpan",
            icon: "success",
          });
          this.$router.push({
            name: "role",
            params: {
              reload: true,
            },
          });
        }
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },

    async getPermissions() {
      let res = await httpService.get(endpoint.permission);
      this.permissions = res.data.data;
      this.setCheckedPermission();
    },

    async showRole() {
      let res = await httpService.get(endpoint.role + `/show/${this.role.id}`);
      let params = res.data.data;
      this.assignParams(params);
    },

    assignParams(params) {
      this.role.id = params.id;
      this.role.name = params.name;
      params.permissions.forEach((element) => {
        this.role.permission_id.push(element.id);
      });
      this.role._method = params.id ? "PUT" : "POST";
    },

    getCheckedPermission() {
      let results = [];
      this.permissions.forEach((element) => {
        if (element["view"]) {
          if (element["view"]["value"] == true) {
            results.push(element["view"]["id"]);
          }
        }
        if (element["create"]) {
          if (element["create"]["value"] == true) {
            results.push(element["create"]["id"]);
          }
        }
        if (element["edit"]) {
          if (element["edit"]["value"] == true) {
            results.push(element["edit"]["id"]);
          }
        }
        if (element["delete"]) {
          if (element["delete"]["value"] == true) {
            results.push(element["delete"]["id"]);
          }
        }
      });
      return results;
    },

    setCheckedPermission() {
      //
      this.permissions.forEach((element, index) => {
        if (element["view"]) {
          if (this.role.permission_id.includes(element["view"]["id"])) {
            this.permissions[index]["view"]["value"] = true;
          }
        }
        if (element["create"]) {
          if (this.role.permission_id.includes(element["create"]["id"])) {
            this.permissions[index]["create"]["value"] = true;
          }
        }
        if (element["edit"]) {
          if (this.role.permission_id.includes(element["edit"]["id"])) {
            this.permissions[index]["edit"]["value"] = true;
          }
        }
        if (element["delete"]) {
          if (this.role.permission_id.includes(element["delete"]["id"])) {
            this.permissions[index]["delete"]["value"] = true;
          }
        }
      });
    },

    can(a) {
      return permissonService.can(a);
    },

    isAdmin(a) {
      return permissonService.isAdmin(a);
    },
  },
};
</script>

<style>
</style>