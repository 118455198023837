<template>
  <tr v-for="a in row" :key="a">
    <td v-for="i in column" :key="i"><FreeStyleShimmer :is-loading="true" height="15px" /></td>
  </tr>
</template>

<script>
import { FreeStyleShimmer } from "vue3-shimmer";
export default {
  components: {
    FreeStyleShimmer,
  },
  name: "TableShimmer",
  props: {
    row: Number,
    column: Number,
  },
};
</script>

<style>
</style>